// ** Initial State
const initialState = {
  item: {},
  list: [],
  step: 1,
  loading_cuotas: false,
  submitted: false,
  form_options: {
    pagares: [],
    cuotas: [],
    cuentas: [],
  },
};

const cheques = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ITEM_CHEQUE':
      return { ...state, item: action.data };
    case 'SET_OPTIONS_CHEQUE':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_UPDATE_CUOTA':
      return {
        ...state,
        form_options: {
          ...state.form_options,
          cuotas: state.form_options.cuotas.map(cuota => {
            if (cuota.id === action.data.cuota) {
              const cheque = action.data;
              return {
                ...cuota,
                num_cheque: cheque.documento || '',
                monto_cheque: cheque.monto || '',
                rut_cheque: cheque.rut_cheque || '',
                cta_cte: cheque.cta_cte || '',
                estado_cheque: cheque.estado || '',
                cheque_id: cheque.id || '',
              };
            }
            return cuota;
          }),
        },
        submitted: true,
      };
    case 'SET_SUBMITTED_CHEQUE':
      return { ...state, submitted: action.data };
    case 'SET_LOADING_CUOTAS':
      return { ...state, loading_cuotas: action.data };
    case 'CLEAR_OPTIONS_CHEQUE':
      return {
        ...state,
        form_options: {
          pagares: [],
          cuotas: [],
          cuentas: [],
        },
      };
    default:
      return { ...state };
  }
};

export default cheques;
