// ** Initial State
const initialState = {
  item: {},
  list: [],
  representantes: [],
  loading: false,
  submmited: false,
  modal: false,
  form_options: {
    pagare_tipos: [],
    cargos_desbloqueante: [],
    rango_vencimiento: 2,
    sucursales: [],
    ciudades: [],
  },
  refreshForm: null,
};

const pagares = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_PAGARE':
      return { ...state, loading: action.data };
    case 'SET_LIST_PAGARES':
      return { ...state, list: action.data };
    case 'SET_PAGARE':
      return { ...state, item: action.data, submmited: false };
    case 'ADD_PAGARE':
      return { ...state, list: [...state.list, action.data], submmited: true };
    case 'UPDATE_PAGARE':
      return {
        ...state,
        list: state.list.map(pagare => {
          return pagare.pagarevista === action.data.pagarevista ? action.data : pagare;
        }),
        submmited: true,
      };
    case 'SET_REPRESENTANTES':
      return { ...state, representantes: action.data };
    case 'CONCAT_REPRESENTANTES':
      return { ...state, representantes: [...state.representantes, ...action.data] };
    case 'ADD_REPRESENTANTE':
      return { ...state, representantes: [...state.representantes, action.data] };
    case 'SET_OPTIONS_PAGARE':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_MODAL':
      return { ...state, modal: action.data };
    case 'REFRESH_FORM_PAGARE':
      return { ...state, refreshForm: action.data };
    default:
      return { ...state };
  }
};
export default pagares;
